@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
    margin: 0;
    padding: 0;
}

html {
    scrollbar-color: #454A4D #202324;
    scrollbar-width: thin;
}

body {
    background: #181A1B;
    color: #D3CFC9;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
}

.wrapper {
    width: 1280px;
    margin: 50px auto;
}

header .logo {
    font-weight: 600;
    font-size: 20px;
}

header ul.nav {
    float: right;
    list-style: none;
}

header ul.nav li {
    display: inline;
    margin-left: 25px;
    cursor: pointer;
    transition: opacity 500ms;
}

header ul.nav li:hover {
    display: inline;
    margin-left: 25px;
    cursor: pointer;
    opacity: 0.5;
}

header .presentation {
    margin: 50px 0;
    background: url('https://source.unsplash.com/random') no-repeat;
    width: 100%;
    height: 500px;
    background-size: cover;
    background-position: center center;
    background-blend-mode: multiply;
    background-color: #363636;
    position: relative;
}

header .presentation::after {
    content: 'Хостинг ваших проектов';
    position: absolute;
    top: 100px;
    left: 50px;
    width: 500px;
    font-size: 40px;
    font-weight: 600;
    color: #fff;
}

header .presentation::before {
    content: 'Предоставляем вычислительные ресурсы для виртуальных серверов и виртуального хостинга.';
    position: absolute;
    top: 200px;
    left: 50px;
    width: 300px;
    font-size: 15px;
    font-weight: 300;
    color: #fff;
}

.categories div {
    display: inline-block;
    background: #1F2223;
    border-radius: 50px;
    padding: 10px 20px;
    margin-bottom: 25px;
    margin-right: 15px;
    cursor: pointer;
    border: 1px solid transparent;
    transition: all 500ms;
}

.categories div:hover {
    border-color: #42474A;
    transform: scale(1.1);
}

main {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
}

main .item {
    border-radius: 10px 10px 0 0;
    width: 30%;
    margin-bottom: 50px;
    background: #1E2021;
    overflow: hidden;
    position: relative;
    padding-bottom: 20px;
}

main .item img, .modal img {
    width: 100%;
    border-radius: 10px 10px 0 0;
    transition: transform 500ms;
    cursor: pointer;
}

.modal {
    scrollbar-color: #454A4D #202324;
    scrollbar-width: thin;
}

.modal img {
    border-radius: 10px;
}

main .item img:hover, .modal img:hover {
    transform: scale(1.01);
}

main h2, .modal h2,
main p, .modal p,
main b, .modal b {
    margin: 10px 20px;
    color: #C8C3BC;
}

main .buyButton, .modal .buyButton {
    position: absolute;
    right: 20px;
    bottom: 10px;
}

.modal-clicker {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.8);
    overflow: auto;
    z-index: 9998;
}

.modal > div {
    width: 600px;
    position: relative;
    margin: 10% auto;
    padding: 40px 30px;
    background: #181A1B;
    border-radius: 9px;
    z-index: 9999;
}

footer {
    text-align: center;
    margin-top: 100px;
}

footer ul {
    list-style: none;
}

footer ul li {
    display: inline;
    margin-left: 25px;
    cursor: pointer;
    transition: opacity 500ms;
}

footer ul li:hover {
    display: inline;
    margin-left: 25px;
    cursor: pointer;
    opacity: 0.5;
}